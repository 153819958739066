h3 {
	margin-bottom: 1rem;
	font-weight: 500;
}

.outterContainer {
	position: relative;
}

.container {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	height: 75px;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	border-radius: 16px;
	background-color: #fdfdfd;
	color: black;
	transition: all 300ms ease-in-out;
	margin-bottom: 1.25rem;
	text-align: justify;
}

.titleTag {
	color: black;
	font-size: 1.4rem;
	font-weight: 500;
	text-align: center;
	text-transform: capitalize;
	margin-bottom: 0.3rem;
	padding: 0;
}

.upper {
	height: 75px;
	padding: 10px;
	background: #e7f7df;
}

.upper.closed span {
	display: inline-block;
	max-width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.upper.expand span {
	height: auto;
	display: inline-block;
	max-width: 100%;
	white-space: normal;
	text-overflow: visible;
}

.lower {
	padding: 10px;
	background-color: #f5f5f5;
}

.upper.closed svg {
	height: 20px;
	width: 16px;
	fill: black;
	margin-left: 5px;
	padding-top: 4px;
	transform: translate(0px, -4px);
}

.upper.expand svg {
	visibility: hidden;
	height: 0px;
}

.lower p:not(:first-child) {
	margin-top: 40px;
}

div.expand {
	height: auto;
}

.posted {
	display: flex;
	justify-content: space-between;
	font-size: 0.75rem;
}
.category {
	background: #d6dfed;
    padding: 3px 10px;
    border-radius: 13px;
}

.editButton {
	background-color: #ac6df3a6;
	min-width: 5rem;
	height: 2rem;
	border-radius: 8px;
	border: none;
	margin: 1rem;
	font-weight: bold;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.editButton:hover {
	background-color: #881bb3;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.deleteButton {
	background-color: #a51d1dc0;
	min-width: 5rem;
	height: 2rem;
	border-radius: 8px;
	border: none;
	margin: 1rem 1rem 2.5rem 1rem;
	font-weight: bold;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.deleteButton:hover {
	background-color: #c92323;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.center {
	justify-content: space-evenly;
	display: flex;
	margin-top: -1.75rem;
}

.newImage {
	transform: translate(-10px, -20px);
	max-width: 75px;
	z-index: 1;
	position: absolute;
}

.updatedIcon {
	transform: translate(-65px, -20px);
	max-width: 75px;
	z-index: 1;
	position: absolute;
}

.largeFileContainer {
	align-items: center;
	justify-content: space-evenly;
	display: block;
	min-height: 5rem;
	margin: 0.5rem;
}

.smallFileContainer {
	margin: 0.5rem;
}
