* {
	box-sizing: border-box;
}

.menu-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-trigger {
	background: #ffffff;
	border-radius: 90px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border: none;
	vertical-align: middle;
	transition: box-shadow 0.4s ease;
	margin-left: auto; /* Strictly for positioning */
	font-weight: bold;
	font-size: 1rem;
}

.menu-trigger:hover {
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
	font-weight: 700;
	vertical-align: middle;
	font-size: 14px;
	margin: 0 10px;
}

.menu-trigger img {
	border-radius: 90px;
}

.menu {
	background: #ffffff;
	border-radius: 8px;
	position: absolute;
	top: 50px;
	right: 0;
	width: 200px;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.menu li {
	border-bottom: 1px solid #dddddd;
}

.menu li:hover{
	background-color: lightgrey;
	border-radius: 8px;
  }

.menu li a {
	text-decoration: none;
	color: #333333;
	padding: 15px 20px;
	display: block;
}

.link {
	margin: 1rem;
	border: 1px solid lightgrey;
	text-align: center;
	border-radius: 8px;
	padding: 0.25rem;
    font-weight: bold;
    cursor: pointer;
}

.link:hover{
    background-color: lightgrey;
}

.outterLink a {
	text-decoration: none;
}