.container {
	width: 100%;
}

.justify {
	display: flex;
	justify-content: space-between;
	max-width: 95%;
}

.input {
	padding: 5px 15px;
	min-width: 95%;
	max-width: 100%;
	font-size: 1.5rem;
	border-radius: 8px;
	background-color: #bfcfe827;
	border: solid 2px #99afd1;
	outline: none;
}

.input:focus {
	border: solid 2px #064ab1;
}

.providerList {
	margin-top: 1em;
	max-height: 80vh; /* Adjust the height as needed */
	overflow-y: auto;
}

.provider {
	list-style: none;
	margin: 0.3em 0;
	padding: 10px;
	background: #bfcfe827;
	display: flex;
	justify-content: space-between;
	border-radius: 8px;
}

.provider:hover {
	background-color: #e7f7df;
}

.providerName {
	color: #5b7eb4;
	font-weight: 500;
	margin: 0rem 0.5rem;
}

.providerGroup {
	margin-left: auto;
	color: #5b7eb4;
	font-weight: 500;
	margin: 0rem 0.5rem;
}

h3 {
	color: #2e3179;
	text-align: center;
}
