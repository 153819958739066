.formControl {
	margin: 0.5rem;
	height: auto;
}

.error {
	font-weight: 500;
	color: #b40e0e;
	display: flex;
	justify-content: center;
}

.center {
	justify-content: space-evenly;
	display: flex;
}

.formEditor {
	display: block !important;
	margin: 0;
	height: 400px;
}

.formControl input,
.formControl label,
.formControl textarea {
	display: flex;
	margin-bottom: 1rem;
}

.formControl label {
	font-weight: bold;
	margin-bottom: 0;
	color: #1f2253;
	font-size: 1.25rem;
}

.formControl input[type='text'],
.formControl textarea,
.formControl select {
	font: inherit;
	padding: 0.5rem;
	border-radius: 8px;
	border: 1px solid #ccc;
	resize: none;
	width: 100%;
}

.formControl input.error,
.formControl textarea.error {
	background-color: #f5e7e7;
	border: 1px solid #b40e0e;
}

.formControl input:focus,
.formControl textarea:focus {
	outline: none;
	border-color: #5b7fb4;
}

.formControl .bigBox {
	display: flex;
	justify-content: space-evenly;
	border: 1px solid lightgrey;
	border-radius: 8px;
	padding: 0.5rem;
}

.formControl .bigBoxError {
	background-color: #f5e7e7;
	border: 1px solid #b40e0e;
	display: flex;
	justify-content: space-evenly;
	border-radius: 8px;
	padding: 0.5rem;
}

.formControl .smallBox,
.formControl .smallBox label,
.formControl .smallBox input[type='checkbox'] {
	position: relative;
	display: block;
	justify-content: center;
	font-size: 1rem;
	font-weight: 500;
	margin: auto;
}

.fileUploadContainer {
	display: flex;
}

.fileUpload {
	margin-bottom: 1rem;
	text-align: center;
	padding-top: 3px;
	font-weight: bold;
	font-size: 1rem;
	border-radius: 80px;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	background-color: #9bc9a3;
	min-width: 5rem;
	max-width: 5rem;
	height: 2rem;
	cursor: pointer;
}

.buttonFacilityForm {
	margin: 1rem;
	font-weight: bold;
	font-size: 1rem;
	border-radius: 8px;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	background-color: #93b9f3;
	min-width: 5rem;
	height: 2rem;
}

.buttonFacilityForm:hover,
.buttonFacilityForm:active {
	background-color: #4d87df;
	cursor: pointer;
}

.buttonFacilityFormInvalidButton,
.buttonFacilityFormInvalidButton:hover,
.buttonFacilityFormInvalidButton:active {
	background-color: grey;
	margin: 1rem;
	font-weight: bold;
	font-size: 1rem;
	border-radius: 8px;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	min-width: 5rem;
	height: 2rem;
}

.buttonFacilityFormReset {
	margin: 1rem;
	font-weight: bold;
	font-size: 1rem;
	border-radius: 8px;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	min-width: 5rem;
	height: 2rem;
	background-color: rgb(197, 188, 56);
	cursor: pointer;
}

.buttonFacilityFormReset:hover {
	background-color: rgba(228, 231, 6, 0.938);
}

.submissionError {
	background-color: #f5e7e7;
	border: 1px solid #b40e0e;
	border-radius: 8px;
	text-align: center;
	font-weight: bold;
	padding: 0.5rem;
}


/*  from https://loading.io/css/  */
.spinner {
	display: inline-block;
	position: relative;
	width: 32px;
	height: 32px;
	margin: 1rem;
}
.spinner div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 32px;
	height: 32px;
	margin: 0px;
	border: 3px solid #74a4f171;
	border-radius: 50%;
	animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #337beecb transparent transparent transparent;
}
.spinner div:nth-child(1) {
	animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
	animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}