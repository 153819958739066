.fileContainer {
	border-radius: 8px;
	background-color: #bfcfe88e;
	padding: 5px;
	height: 75%;
	margin: 0 auto;
	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
}

.fileContainer a{
	text-decoration: none;
	color: black;
}

.innerContainer {
	display: flex;
	justify-content: left;
}

.uploadIcon {
	justify-content: left;
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-height: 40px;
	width: 10%;
}

.fileName {
	width: 100%;
	justify-content: left;
	text-align: left;
	margin: auto;
	text-align: left;
	font-size: 0.75rem;
	font-weight: 500;
}


