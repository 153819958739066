.facilityName {
	text-transform: uppercase;
}

a {
	color: blue;
}

.scrollableSection {
	max-height: 75vh; /* Adjust the height as needed */
	overflow-y: auto;
}
