@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

body {
	background-color: #bfcfe827;
}

h2 {
	color: #2c292b;
	text-align: center;
}

p {
	text-align: justify;
	text-justify: inter-word;
	margin-bottom: 1rem;
}
/* 
ul {
	list-style: none;
	margin: 0;
} */

/* li {
	list-style-type: disc;
	text-align: left;
} */

a {
	text-decoration: none;
}
