.fileUpload {
	display: flex;
}

.fileUpload img,
.fileUpload img:hover {
	height: 32px;
	cursor: pointer;
}

.fileUpload .spacer {
	min-width: 1rem;
}

.fileUploadContainer {
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	border: 1px solid lightgrey;
	border-radius: 8px;
	min-height: 10rem;
}

.error {
	font-weight: 500;
	color: #b40e0e;
	display: flex;
	justify-content: center;
	align-items: center;
}

/*  from https://loading.io/css/  */
.spinner {
	display: inline-block;
	position: relative;
	width: 32px;
	height: 32px;
	margin: 0;
}
.spinner div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 32px;
	height: 32px;
	margin: 0px;
	border: 3px solid #74a4f171;
	border-radius: 50%;
	animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #337beecb transparent transparent transparent;
}
.spinner div:nth-child(1) {
	animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
	animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
