.cancelButton {
	background-color: #ccd554e1;
	min-width: 5rem;
	height: 2rem;
	border-radius: 8px;
	border: none;
	margin: 1rem 0 0 0;
	font-weight: bold;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.cancelButton:hover {
	background-color: #dfea3f;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.deleteButton {
	background-color: #a51d1dc0;
	min-width: 5rem;
	height: 2rem;
	border-radius: 8px;
	border: none;
	margin: 1rem 0 0 0;
	font-weight: bold;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.deleteButton:hover {
	background-color: #c92323;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.center {
	justify-content: space-evenly;
	display: flex;
}

.statement {
	text-align: center;
}

span.title {
	font-weight: bold;
	padding: 0;
	margin: 0;
}
