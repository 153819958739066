.container {
	margin: 3rem 2rem;
}

.containerInner {
	margin: 0 2rem 0 2rem;
}

.containerAdmin {
	margin: 1rem;
}

h1 {
	font-weight: 500;
	color: #3b3d80;
	text-align: center;
}

.center {
	text-align: center;
}

.success {
	background-color: rgb(212, 243, 212);
	border: 1px solid rgb(76, 201, 76);
	border-radius: 8px;
	text-align: center;
	font-weight: bold;
	padding: 0.5rem;
}

.failure {
	background-color: #f5e7e7;
	border: 1px solid #b40e0e;
	border-radius: 8px;
	text-align: center;
	font-weight: bold;
	padding: 0.5rem;
}

label.sort {
	font-weight: bold;
	font-size: 1rem;
	justify-content: space-around;
	display: contents;
}

select {
	border: solid 1px lightgrey;
	outline: solid 0px lightgrey;
	border-radius: 8px;
	font-weight: 500;
}
